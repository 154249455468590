<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected"/>
        <v-card>
            <v-card-title>
            {{ Titulo }}
            <v-spacer></v-spacer>
            <v-btn
                v-if="Excel"
                color = "primary"
                dark
                small
                class="mb-2"
                @click="DescargarExcel()"
            >
            Descargar Tabla
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Busqueda"
                single-line
                hide-details
            ></v-text-field>            
            <v-spacer></v-spacer>
            <v-btn
                v-if="Crear"
                color="primary"
                dark
                class="mb-2"
                @click="nuevoItem()"
                >
                Nuevo
            </v-btn>
            </v-card-title>
            <v-card-text>
            <v-data-table
            dense
            :headers="Cabecera"
            :items="Items"
            :items-per-page="5"
            :search="search"
            class="elevation-1"
            >            
                <template v-slot:[`item.Accion`]="{ item }">                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                height="22"
                                width="22"
                                color="blue lighten-2"
                                fab
                                x-small
                                dark
                                @click="editarItem(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Editar</span> 
                    </v-tooltip>

                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                style="margin-left:5px"
                                height="22"
                                width="22"
                                color="orange lighten-2"
                                fab
                                x-small
                                dark
                                @click="generateReport(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-file</v-icon>
                            </v-btn>  
                        </template>
                        <span>Pre Visualizar Pdf</span> 
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                style="margin-left:5px"
                                height="22"
                                width="22"
                                color="red lighten-2"
                                fab
                                x-small
                                dark
                                @click="descargarReport(item)"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-small>mdi-file</v-icon>
                            </v-btn>  
                        </template>
                        <span>Descargar Pdf</span> 
                    </v-tooltip>
                </template>
            </v-data-table>
            </v-card-text>
        </v-card>

        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="PdfContrato"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            
            pdf-content-width="800px"
            ref="html2Pdf"
        >
        <section slot="pdf-content"> 
            <section class="pdf-item">
            <section class="pdf-item" style="padding:20px">
                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    >
                        <img src="/dama.jpeg"          
                        height="180"
                        width="125" >
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                        align="center"
                    >
                    <div align="center" style="margin-bottom:10px" >
                        <img src="/logo.png"          
                        height="95"
                        width="180" >                    
                    </div>
                    <b><span style="font-size:12px" >DE: ZAÑARTU GONZALES KARLA SILVANA</span></b><br>
                    <span style="font-size:12px" >Exclusividad en vestidos de novia, Fiestas, 15 años,<br> Promociones y alquiler de vestidos</span><br>
                    <b><span style="font-size:10px;" >LADISLAO ESPINAR N° 67 - CEL 977298203 - PACASMAYO - LA LIBERTAD</span></b><br>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    >
                    <v-card
                        color="#FFF"
                        border="1"
                    >
                        <v-toolbar 
                        align="center"
                        height="40"
                        color="#607D8B">
                        <b><span style="color:white;font-size:16px" >CONTRATO</span></b>
                        </v-toolbar>
                        <v-card-subtitle align="center" >          
                        <span style="align:center;font-size:14px" >R.U.C 10434718169 </span> <br>
                        <span style="color:red;font-size:14px" align="center" >{{ItemsPdfContrato['numdocumento']}}</span><br>
                        </v-card-subtitle>                    
                    </v-card>

                    <v-card class="pa-0 ma-0">                 
                        <v-chip-group
                        active-class="deep-purple--text text--accent-4"
                        style="padding-left:8px"
                        >
                        <v-chip color="#607D8B" style="color:white"><b>{{ItemsPdfContrato['dia']}}</b></v-chip>
                        <v-chip color="#607D8B" style="color:white"><b>{{ItemsPdfContrato['mes']}}</b></v-chip>
                        <v-chip color="#607D8B" style="color:white"><b>{{ItemsPdfContrato['año']}}</b></v-chip>
                        </v-chip-group>
                    </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="margin-top:20px"
                        align="left"
                    >
                        <v-row>
                            <v-col
                                cols="7"
                                md="7"
                                sm="7"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px; " ><b>Sr. </b>{{ItemsPdfContrato['responsable']}}</span>
                            </v-col>
                            <v-col
                                cols="5"
                                md="5"
                                sm="5"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px" ><b>N° Documento: </b>{{ItemsPdfContrato['RES_NumeroDocumento']}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="7"
                                md="7"
                                sm="7"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px; " ><b>Dirección: </b>{{ItemsPdfContrato['RES_Direccion']}}</span>
                            </v-col>
                            <v-col
                                cols="5"
                                md="5"
                                sm="5"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px" ><b>Tipo Contrato: </b>{{ItemsPdfContrato['tipo']}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="6"
                                md="6"
                                sm="6"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px;" ><b>E-mail: </b>{{ItemsPdfContrato['RES_Email']}}</span>
                            </v-col>
                            <v-col
                                cols="6"
                                md="6"
                                sm="6"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px" ><b>Telefono: </b>{{ItemsPdfContrato['RES_Telefono']}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="12"
                                sm="12"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px;" ><b>Descripción: </b>{{ItemsPdfContrato['CON_Descripcion']}}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>        
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th width="10%" class="text-left">
                                CANT.
                                </th>
                                <th width="80%" class="text-left">
                                DESCRIPCION
                                </th>
                                <th width="10%" class="text-left">
                                Importe
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item,i) in ItemsPdfDetalle"
                                :key="i"
                            >
                                <td class="text-left" >{{ item.CON_Cantidad }}</td>
                                <td class="text-left" >{{item.TIP_Nombre}} / {{ item.PRO_Nombre }} {{ item.PRO_Color }} {{ item.PRO_Encaje }} {{item.PRO_Tipo_Tela}}</td>
                                <td class="text-left" >{{ item.importe }}</td>
                            </tr>
                            </tbody>
                        </template>
                        </v-simple-table>
                    </v-col>
                    <v-col
                        cols="12"
                        md="5"
                        sm="12"
                    >
                    </v-col>
                    <v-col
                        cols="12"
                        md="7"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr v-if="ItemsPdfContrato['CON_Garantia']">
                                <th width="40%" class="text-left">
                                GARANTIA: S/. 
                                </th>
                                <th width="30%" class="text-left">
                                
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Garantia'] }}
                                </th>
                            </tr>
                            <tr v-if="ItemsPdfContrato['CON_Fecha1']">
                                <th width="40%" class="text-left">
                                1ra. Prueba 
                                </th>
                                <th width="30%" class="text-left">
                                {{ItemsPdfContrato['CON_Fecha1']}}
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Adelanto1'] }}
                                </th>
                            </tr>
                            <tr v-if="ItemsPdfContrato['CON_Fecha2']">
                                <th width="40%" class="text-left">
                                2da. Prueba 
                                </th>
                                <th width="30%" class="text-left">
                                {{ItemsPdfContrato['CON_Fecha2']}}
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Adelanto2'] }}
                                </th>
                            </tr>
                            <tr v-if="ItemsPdfContrato['CON_Fecha3']">
                                <th width="40%" class="text-left">
                                3ra. Prueba 
                                </th>
                                <th width="30%" class="text-left">
                                {{ItemsPdfContrato['CON_Fecha3']}}
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Adelanto3'] }}
                                </th>
                            </tr>
                            <tr >
                                <th width="40%" class="text-left">
                                <b><label style="color:red">SALDO PENDIENTE </label></b>
                                </th>
                                <th width="30%" class="text-left">
                                
                                </th>
                                <th width="30%" class="text-right">
                                <b><label style="color:red">{{ItemsPdfContrato['importe'] - ItemsPdfContrato['CON_Garantia']  - ItemsPdfContrato['CON_Adelanto1']  - ItemsPdfContrato['CON_Adelanto2']  - ItemsPdfContrato['CON_Adelanto3']  }}</label></b>
                                </th>
                            </tr>
                            </thead>
                            <thead>
                                <tr border="2">
                                    <th width="40%" class="text-left">
                                    <b><label style="color:black">IMPORTE TOTAL</label></b>
                                    </th>
                                    <th width="30%" class="text-left">
                                    
                                    </th>
                                    <th width="30%" class="text-right">
                                    <b><label style="color:black">{{ItemsPdfContrato['importe']  }}</label></b>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        </v-simple-table>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th width="30%" class="text-left">
                                
                                </th>
                                <th width="40%" class="text-center">
                                MEDIDAS
                                </th>
                                <th width="30%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>Cliente : </b></label> {{ItemsPdfMedidas['CLI_Nombre'] }}
                                </th>
                                <th width="33%" class="text-left">
                                </th>
                                <th width="34%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="30%" class="text-left">
                                    <label ><b>Busto : </b></label> {{ItemsPdfMedidas['DCM_Busto'] }}
                                </th>
                                <th width="30%" class="text-left">
                                    <label ><b>Cintura : </b></label> {{ItemsPdfMedidas['DCM_Cintura'] }}
                                </th>
                                <th width="40%" class="text-left">
                                    <label ><b>Espalda : </b></label> {{ItemsPdfMedidas['DCM_Espalda'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="34%" class="text-left">
                                    <label ><b>Cuello : </b></label> {{ItemsPdfMedidas['DCM_Cuello'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>Sisa : </b></label> {{ItemsPdfMedidas['DCM_Sisa'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>Talle : </b></label> {{ItemsPdfMedidas['DCM_Talla'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>A. Busto : </b></label> {{ItemsPdfMedidas['DCM_ABusto'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>Cadera : </b></label> {{ItemsPdfMedidas['DCM_Cadera'] }}
                                </th>
                                <th width="34%" class="text-left">
                                    <label ><b>A. Cadera : </b></label> {{ItemsPdfMedidas['DCM_ACadera'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>l. Falda : </b></label> {{ItemsPdfMedidas['DCM_LFalda'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>L. Manga : </b></label> {{ItemsPdfMedidas['DCM_LManga'] }}
                                </th>
                                <th width="34%" class="text-left">
                                    <label ><b>C. Manga : </b></label> {{ItemsPdfMedidas['DCM_CManga'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>C. Puño : </b></label> {{ItemsPdfMedidas['DCM_CPuño'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>A. Bajo : </b></label> {{ItemsPdfMedidas['DCM_ABajoBusto'] }}
                                </th>
                                <th width="34%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                        </template>
                        </v-simple-table>
                    </v-col>
                    
                    <v-col
                        class="mt-10"
                        cols="12"
                        md="12"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th width="100%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="100%" class="text-left">
                                    No hay lugar a devolución <br>
                                    Nota: En caso de deterioro de la prenda se descontará la garantía
                                </th>
                            </tr>
                            </thead>
                        </template>
                        </v-simple-table>
                    </v-col>
                </v-row>            
            </section>  
        </section>
        </section> 
        </VueHtml2pdf> 
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="PdfContrato"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            
            pdf-content-width="800px"
            ref="html2Pdfdescargar"
        >
        <section slot="pdf-content"> 
            <section class="pdf-item">
            <section class="pdf-item" style="padding:20px">
                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    >
                        <img src="/dama.jpeg"          
                        height="180"
                        width="125" >
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        sm="12"
                        align="center"
                    >
                    <div align="center" style="margin-bottom:10px" >
                        <img src="/logo.png"          
                        height="95"
                        width="180" >                    
                    </div>
                    <b><span style="font-size:12px" >DE: ZAÑARTU GONZALES KARLA SILVANA</span></b><br>
                    <span style="font-size:12px" >Exclusividad en vestidos de novia, Fiestas, 15 años,<br> Promociones y alquiler de vestidos</span><br>
                    <b><span style="font-size:10px;" >LADISLAO ESPINAR N° 67 - CEL 977298203 - PACASMAYO - LA LIBERTAD</span></b><br>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    >
                    <v-card
                        color="#FFF"
                        border="1"
                    >
                        <v-toolbar 
                        align="center"
                        height="40"
                        color="#607D8B">
                        <b><span style="color:white;font-size:16px" >CONTRATO</span></b>
                        </v-toolbar>
                        <v-card-subtitle align="center" >          
                        <span style="align:center;font-size:14px" >R.U.C 10434718169 </span> <br>
                        <span style="color:red;font-size:14px" align="center" >{{ItemsPdfContrato['numdocumento']}}</span><br>
                        </v-card-subtitle>                    
                    </v-card>

                    <v-card class="pa-0 ma-0">                 
                        <v-chip-group
                        active-class="deep-purple--text text--accent-4"
                        style="padding-left:8px"
                        >
                        <v-chip color="#607D8B" style="color:white"><b>{{ItemsPdfContrato['dia']}}</b></v-chip>
                        <v-chip color="#607D8B" style="color:white"><b>{{ItemsPdfContrato['mes']}}</b></v-chip>
                        <v-chip color="#607D8B" style="color:white"><b>{{ItemsPdfContrato['año']}}</b></v-chip>
                        </v-chip-group>
                    </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="margin-top:20px"
                        align="left"
                    >
                        <v-row>
                            <v-col
                                cols="7"
                                md="7"
                                sm="7"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px; " ><b>Sr. </b>{{ItemsPdfContrato['responsable']}}</span>
                            </v-col>
                            <v-col
                                cols="5"
                                md="5"
                                sm="5"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px" ><b>N° Documento: </b>{{ItemsPdfContrato['RES_NumeroDocumento']}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="7"
                                md="7"
                                sm="7"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px; " ><b>Dirección: </b>{{ItemsPdfContrato['RES_Direccion']}}</span>
                            </v-col>
                            <v-col
                                cols="5"
                                md="5"
                                sm="5"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px" ><b>Tipo Contrato: </b>{{ItemsPdfContrato['tipo']}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="6"
                                md="6"
                                sm="6"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px;" ><b>E-mail: </b>{{ItemsPdfContrato['RES_Email']}}</span>
                            </v-col>
                            <v-col
                                cols="6"
                                md="6"
                                sm="6"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px" ><b>Telefono: </b>{{ItemsPdfContrato['RES_Telefono']}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="12"
                                sm="12"
                                align="left"
                                style="padding-left:25px;padding-top:5px;padding-bottom:0px"
                            >
                                <span style="font-size:14px;" ><b>Descripción: </b>{{ItemsPdfContrato['CON_Descripcion']}}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>        
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th width="10%" class="text-left">
                                CANT.
                                </th>
                                <th width="80%" class="text-left">
                                DESCRIPCION
                                </th>
                                <th width="10%" class="text-left">
                                Importe
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item,i) in ItemsPdfDetalle"
                                :key="i"
                            >
                                <td class="text-left" >{{ item.CON_Cantidad }}</td>
                                <td class="text-left" >{{item.TIP_Nombre}} / {{ item.PRO_Nombre }} {{ item.PRO_Color }} {{ item.PRO_Encaje }} {{item.PRO_Tipo_Tela}}</td>
                                <td class="text-left" >{{ item.importe }}</td>
                            </tr>
                            </tbody>
                        </template>
                        </v-simple-table>
                    </v-col>
                    <v-col
                        cols="12"
                        md="5"
                        sm="12"
                    >
                    </v-col>
                    <v-col
                        cols="12"
                        md="7"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr v-if="ItemsPdfContrato['CON_Garantia']">
                                <th width="40%" class="text-left">
                                GARANTIA: S/. 
                                </th>
                                <th width="30%" class="text-left">
                                
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Garantia'] }}
                                </th>
                            </tr>
                            <tr v-if="ItemsPdfContrato['CON_Fecha1']">
                                <th width="40%" class="text-left">
                                1ra. Prueba 
                                </th>
                                <th width="30%" class="text-left">
                                {{ItemsPdfContrato['CON_Fecha1']}}
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Adelanto1'] }}
                                </th>
                            </tr>
                            <tr v-if="ItemsPdfContrato['CON_Fecha2']">
                                <th width="40%" class="text-left">
                                2da. Prueba 
                                </th>
                                <th width="30%" class="text-left">
                                {{ItemsPdfContrato['CON_Fecha2']}}
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Adelanto2'] }}
                                </th>
                            </tr>
                            <tr v-if="ItemsPdfContrato['CON_Fecha3']">
                                <th width="40%" class="text-left">
                                3ra. Prueba 
                                </th>
                                <th width="30%" class="text-left">
                                {{ItemsPdfContrato['CON_Fecha3']}}
                                </th>
                                <th width="30%" class="text-right">
                                {{ItemsPdfContrato['CON_Adelanto3'] }}
                                </th>
                            </tr>
                            <tr >
                                <th width="40%" class="text-left">
                                <b><label style="color:red">SALDO PENDIENTE </label></b>
                                </th>
                                <th width="30%" class="text-left">
                                
                                </th>
                                <th width="30%" class="text-right">
                                <b><label style="color:red">{{ItemsPdfContrato['importe'] - ItemsPdfContrato['CON_Garantia']  - ItemsPdfContrato['CON_Adelanto1']  - ItemsPdfContrato['CON_Adelanto2']  - ItemsPdfContrato['CON_Adelanto3']  }}</label></b>
                                </th>
                            </tr>
                            </thead>
                            <thead>
                                <tr border="2">
                                    <th width="40%" class="text-left">
                                    <b><label style="color:black">IMPORTE TOTAL</label></b>
                                    </th>
                                    <th width="30%" class="text-left">
                                    
                                    </th>
                                    <th width="30%" class="text-right">
                                    <b><label style="color:black">{{ItemsPdfContrato['importe']  }}</label></b>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        </v-simple-table>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th width="30%" class="text-left">
                                
                                </th>
                                <th width="40%" class="text-center">
                                MEDIDAS
                                </th>
                                <th width="30%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>Cliente : </b></label> {{ItemsPdfMedidas['CLI_Nombre'] }}
                                </th>
                                <th width="33%" class="text-left">
                                </th>
                                <th width="34%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="30%" class="text-left">
                                    <label ><b>Busto : </b></label> {{ItemsPdfMedidas['DCM_Busto'] }}
                                </th>
                                <th width="30%" class="text-left">
                                    <label ><b>Cintura : </b></label> {{ItemsPdfMedidas['DCM_Cintura'] }}
                                </th>
                                <th width="40%" class="text-left">
                                    <label ><b>Espalda : </b></label> {{ItemsPdfMedidas['DCM_Espalda'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="34%" class="text-left">
                                    <label ><b>Cuello : </b></label> {{ItemsPdfMedidas['DCM_Cuello'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>Sisa : </b></label> {{ItemsPdfMedidas['DCM_Sisa'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>Talle : </b></label> {{ItemsPdfMedidas['DCM_Talla'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>A. Busto : </b></label> {{ItemsPdfMedidas['DCM_ABusto'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>Cadera : </b></label> {{ItemsPdfMedidas['DCM_Cadera'] }}
                                </th>
                                <th width="34%" class="text-left">
                                    <label ><b>A. Cadera : </b></label> {{ItemsPdfMedidas['DCM_ACadera'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>l. Falda : </b></label> {{ItemsPdfMedidas['DCM_LFalda'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>L. Manga : </b></label> {{ItemsPdfMedidas['DCM_LManga'] }}
                                </th>
                                <th width="34%" class="text-left">
                                    <label ><b>C. Manga : </b></label> {{ItemsPdfMedidas['DCM_CManga'] }}
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="33%" class="text-left">
                                    <label ><b>C. Puño : </b></label> {{ItemsPdfMedidas['DCM_CPuño'] }}
                                </th>
                                <th width="33%" class="text-left">
                                    <label ><b>A. Bajo : </b></label> {{ItemsPdfMedidas['DCM_ABajoBusto'] }}
                                </th>
                                <th width="34%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                        </template>
                        </v-simple-table>
                    </v-col>
                    <v-col
                        class="mt-10"
                        cols="12"
                        md="12"
                        sm="12"
                    >
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th width="100%" class="text-left">
                                </th>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <th width="100%" class="text-left">
                                    No hay lugar a devolución <br>
                                    Nota: En caso de deterioro de la prenda se descontará la garantía
                                </th>
                            </tr>
                            </thead>
                        </template>
                        </v-simple-table>
                    </v-col>
                </v-row>            
            </section>  
        </section>
        </section> 
        </VueHtml2pdf>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Nuevo from './nuevo.vue'
import Pdf from './Pdf.vue'
import exportFromJSON from "export-from-json"
export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear"],
    components: {
        Nuevo,
        Pdf
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: { }
        };
    },
    methods: {
        ...mapActions("contrato", ["detalleContrato","editContrato","nuevoContrato"]),
        nuevoItem() {
            this.nuevoContrato()
            .then(r => {
                this.$router.replace({ path: 'contrato' })
            })
        },
        editarItem(item) {
            this.editContrato(item.id)
            .then(r => {
                this.$router.replace({ path: '/contrato' })
            })
            
        },
        generateReport(item) {
            this.detalleContrato(item.id)
            .then(r => {
                this.$refs.html2Pdf.generatePdf()
            })
            //
        },
        descargarReport(item) {
            this.detalleContrato(item.id)
            .then(r => {
                this.$refs.html2Pdfdescargar.generatePdf()
                this.$alertify.success("Se esta Descargando");
            })
            //
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        DescargarExcel(){
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({data, nombreArchivo, exportType})
        }
    },
    computed:{
        ...mapGetters('contrato',['ItemsPdfContrato','ItemsPdfDetalle','ItemsPdfMedidas'])    
    }
};
</script>
